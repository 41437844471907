<template>
    <div class="filters mb-3">
        <div class="row">

            <div class="col-lg">
                <input type="text" class="form-control mb-3" name="search" id="search" @keyup="searchbar()"
                       v-model="search" placeholder="Zoeken..."/>
            </div>

            <div class="col-lg-2" v-if="filters.organisations.length > 1">
                <select name="organisation" class="form-control" v-model="organisation" @change="getOrders()">
                    <option value="0">-- Alle organisaties --</option>
                    <option v-for="organisation in filters.organisations"
                            :value="organisation.id">{{ organisation.name }}
                    </option>
                </select>
            </div>

            <div class="col-lg-2" v-if="typeSelector">
                <select name="warehouse" id="warehouse" v-model="warehouse" @change="getOrders(1)" class="form-control">
                    <option value="">Klant en magazijn</option>
                    <option value="0">Klantorders</option>
                    <option value="1">Magazijnorders</option>
                </select>
            </div>

            <div class="col-lg-2" v-if="planningSelector">
                <select name="type" id="type" v-model="type" @change="getOrders(1)" class="form-control">
                    <option value="">Alle orders</option>
                    <option value="no_preference">Zonder voorkeursdag</option>
                    <option value="late_preference">Laatste voorkeursdag geweest</option>
                    <option value="late_until">Ouder dan bezorgtermijn</option>
                </select>
            </div>

            <div class="col-lg-2">
                <select name="payment" id="payment" v-model="payment" @change="getOrders(1)" class="form-control">
                    <option value="">Alle orders</option>
                    <option value="not_in3" v-if="!in3Orders">Niet in3</option>
                    <option value="in3" v-if="!in3Orders">In3</option>
                    <option value="first_payment">1e betaling</option>
                    <option value="second_payment">2e betaling</option>
                    <option value="delivered">Bezorgd 2e betaling</option>
                    <option value="third_payment">3e betaling</option>
                </select>
            </div>
        </div>
    </div>

    <a :href=" route('orders.create')" class="btn btn-success mb-3" v-if="addOrdersCustomer">
        <i class="far fa-file-invoice mr-2"></i> Voeg nieuwe order toe
    </a>
    <a :href=" route('orders.warehouse-order')" class="btn btn-secondary mb-3 ml-2" v-if="addOrdersWarehouse">
        <i class="far fa-warehouse-alt mr-2"></i> Voeg magazijn order toe
    </a>

    <a :class="'list-row ' + (order.retour ? 'bg-retour' : '') +''" :href="'/orders/' + order.id + '/edit'"
       v-for="order in orders">
        <div class="row align-items-center">
            <div class="col-2">
                <span :class="'badge badge-' + order.status_color + ' px-1 py-2 mr-1'" :title="order.status_name">
                    <i :class="'far fa-fw fa-' + order.status_icon"></i>
                </span>
                <strong>#{{ order.order_nr }}</strong>
            </div>
            <div class="col">
                {{ order.name }}
                <span v-if="order.warehouse" class="badge badge-warehouse"><i
                    class="far fa-warehouse-alt"></i> {{ order.abb_order_nr }}</span>
            </div>
            <div class="col d-flex justify-content-between">
                {{ order.organisation.name }}
                <span class="badge badge-info text-white" v-if="order.type === 'pixel_in3'"
                      title="In drie delen betalen">
	            <i class="far fa-2x fa-usd-square"></i>
                </span>
            </div>
            <div class="col" :title="order.created_at">{{ order.created_at_formated }}</div>
            <div v-if="order.schedule_id" class="col">gepland voor {{ order.schedule.date_formatted }}</div>
            <div v-else class="col"></div>
            <div v-if="in3Orders" class="col text-danger">
                <div v-if="order.subsequent_deliveries.length > 0">Heeft nalevering</div>
            </div>

            <div class="col-1 text-right">
                <i class="fas fa-angle-right"></i>
            </div>
        </div>
    </a>

    <div class="d-flex justify-content-end mt-3">
        <div v-if="pagination.amount_pages > 1" class="list-item list-pagination">
            <ul class="pagination">
                <li class="page-item" v-for="page in pagination.pages"
                    :class="pagination.current_page === page ? 'active' : ''">
                    <span class="page-link" v-if="page === '...'">{{ page }}</span>
                    <a v-else @click="getOrders(page)" class="page-link">{{ page }}</a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "Index",
    props: [
        'typeSelector',
        'planningSelector',
        'addOrdersCustomer',
        'addOrdersWarehouse',
        'notInSchedule',
        'in3Orders',
    ],
    data() {
        const query = new URLSearchParams(window.location.search);

        return {
            orders: {},
            pagination: {
                current_page: parseInt(query.get('page') ?? 1),
                total: '',
                amount_pages: '',
                pages: [],
            },
            search: query.get('s') ?? '',
            filters: {
                organisations: [],
            },
            warehouse: query.get('w') ?? '',
            type: query.get('t') ?? '',
            payment: query.get('p') ?? '',
            organisation: query.get('organisation') ?? 0,
        }
    },
    methods: {
        getOrders(page) {
            const self = this;
            if (page) {
                this.pagination.current_page = page;
            }

            window.history.pushState(
                {s: this.search},
                "",
                "?s=" + this.search
                + "&page=" + this.pagination.current_page
                + "&organisation=" + this.organisation
                + "&w=" + this.warehouse
                + "&i=" + this.in3Orders
                + "&t=" + this.type
                + "&p=" + this.payment
            );

            axios({
                method: 'get',
                url: route('orders.json'),
                params: {
                    page: this.pagination.current_page,
                    search: this.search,
                    organisation: this.organisation,
                    ongepland: this.notInSchedule,
                    in3Orders: this.in3Orders,
                    warehouse: this.warehouse,
                    type: this.type,
                    payment: this.payment,
                }
            }).then(function (response) {
                console.log(self.orders);
                self.orders = response.data.data;
                self.pagination.total = response.data.total;
                self.pagination.amount_pages = parseInt(response.data.last_page);
                self.pagination.pages = self.pageRange();
            })
        },
        getOrganisations() {
            const self = this;
            axios({
                method: 'get',
                url: route('organisations.json'),
            }).then(function (response) {
                self.filters.organisations = response.data;
            })
        },
        searchbar() {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(() => {
                this.getOrders(1);
            }, 200);
        },
        pageRange() {
            if (this.pagination.amount_pages < 1) {
                return [1, 2, 3]
            }

            const current = parseInt(this.pagination.current_page);
            const last = this.pagination.amount_pages;
            const delta = 2;
            const left = current - delta;
            const right = current + delta + 1;
            const range = [];
            const pages = [];
            let l;
            for (let i = 1; i <= last; i++) {
                if (i === 1 || i === last || (i >= left && i < right)) {
                    range.push(i);
                }
            }
            range.forEach(function (i) {
                if (l) {
                    if (i - l === 2) {
                        pages.push(l + 1);
                    } else if (i - l !== 1) {
                        pages.push('...');
                    }
                }
                pages.push(i);
                l = i;
            });
            return pages;
        },
    },
    beforeMount() {
        this.getOrders(this.pagination.current_page);
        this.getOrganisations();
    }
}
</script>
