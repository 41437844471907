<template>
    <form :action="route('statistics.csv')" method="get" class="filters mb-3">
        <div class="row no-gutters">
            <div class="col-lg row no-gutters">


                <div class="col-lg-2 pr-3 mb-2">
                    <input type="date" class="form-control py-2" name="from_date" v-model="from_date"
                           @change="getOrders()">
                </div>

                <div class="col-lg-2 pr-3 mb-2">
                    <input type="date" class="form-control py-2" name="to_date" v-model="to_date" @change="getOrders()">
                </div>

                <div class="col-lg-3 pr-3 mb-2">
                    <select name="organisation" class="form-control" v-model="organisation" @change="getOrders()">
                        <option value="0">-- Alle organisaties --</option>
                        <option v-for="organisation in filters.organisations" :value="organisation.id">
                            {{ organisation.name }}
                        </option>
                    </select>
                </div>

                <div class="col-lg-3 pr-3 mb-2">
                    <select name="status" class="form-control" v-model="status" @change="getOrders()">
                        <option value="all-positive">-- Alle positieve statussen --</option>
                        <option :value="value" v-for="(label, value) in statuses">{{ label }}</option>
                        <option value="all">Alle statussen (inclusief niet actieve)</option>
                    </select>
                </div>

                <div class="col-lg-2 pr-3 mb-2">
                    <select name="amount" class="form-control" v-model="amount" @change="getOrders()">
                        <option value="20">20</option>
                        <option value="50" selected>50</option>
                        <option value="100">100</option>
                        <option value="1000">1000</option>
                    </select>
                </div>

                <div class="col-lg-3 pr-3 mb-2" v-if="ordersCustomer && ordersWarehouse">
                    <select name="w" class="form-control" v-model="warehouse" @change="getOrders()">
                        <option value="">Klant en magazijn</option>
                        <option value="0">Klantorders</option>
                        <option value="1">Magazijnorders</option>
                    </select>
                </div>

                <div class="col-lg-3 pr-3 mb-2">
                    <select name="subsequent_delivery" class="form-control" v-model="subsequent_delivery"
                            @change="getOrders()">
                        <option value="">Orders en naleveringen</option>
                        <option value="1">Alleen naleveringen</option>
                        <option value="0">Alleen orders</option>
                    </select>
                </div>
                <div class="col-lg-3 pr-3 mb-2">
                    <select name="planned_orders" class="form-control" v-model="planned_orders" @change="getOrders()">
                        <option value="">Gepland en ongepland orders</option>
                        <option value="1">Alleen gepland</option>
                        <option value="0">Alleen ongepland</option>
                    </select>
                </div>

                <div class="col-lg-3 pr-3 mb-2">
                    <select name="payment" id="payment" v-model="payment" @change="getOrders()" class="form-control">
                        <option value="">Alle orders</option>
                        <option value="not_in3">Niet in3</option>
                        <option value="in3">In3</option>
                        <option value="first_payment">1e betaling</option>
                        <option value="second_payment">2e betaling</option>
                        <option value="delivered">Bezorgd 2e betaling</option>
                        <option value="third_payment">3e betaling</option>
                    </select>
                </div>
            </div>

            <div class="col-lg-auto">
                <button type="submit" class="btn btn-primary" data-custom-submit>
                    <i class="far fa-file-csv"></i>
                    Exporteren als CSV
                </button>
            </div>

        </div>
        <div id="progress"></div>
    </form>

    <div class="row no-gutters">
        <div class="col-lg-2">
            <div class="statistics statistics-totals">
                <small>Totaal</small>
                {{ pagination.total }}
            </div>
        </div>
        <div v-for="org in organisation_results" class="col-lg-2">
            <div class="statistics statistics-hover" v-on:click="organisation = org.id; getOrders();">
                <div class="row">
                    <div class="col-6 d-flex flex-column">
                        <small class="d-block text-truncate" :title="org.name">{{ org.name }}</small>
                        {{ org.amount_orders }}
                    </div>
                    <div class="col-6">
                        <div class="d-flex justify-content-between gap-1">
                            <small class="d-block text-truncate" title="Orders">Orders</small>
                            <small class="font-weight-bold">{{ org.amount_original_orders }}</small>
                        </div>
                        <div class="d-flex justify-content-between gap-1">

                            <small class="d-block text-truncate" title="Naleveringen">Naleveringen</small>
                            <small class="font-weight-bold">{{ org.amount_subsequent_deliveries }}</small>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="statistics-header mt-3">
        <div class="row">
            <div class="col-lg-2">Ordernummer</div>
            <div class="col">Naam klant</div>
            <div class="col">Organisatie</div>
            <div class="col-1">Producten</div>
            <div class="col-1">Status</div>
            <div class="col-2 text-right">Orderdatum</div>
            <div class="col-1 text-right">Tijdstip</div>
        </div>
    </div>


    <a class="statistics-row" :href="route('orders.edit', order.id)" v-for="order in orders">
        <div class="row align-items-center">
            <div class="col-2">#{{ order.order_nr }}</div>
            <div class="col">{{ order.name }}</div>
            <div class="col">{{ order.organisation.name }}
                <span v-if="order.warehouse" class="badge badge-warehouse"><i
                    class="far fa-warehouse-alt"></i> {{ order.abb_order_nr }}</span>
            </div>
            <div class="col-1">{{ countProducts(order) }}</div>
            <div class="col-1">
                <span :class="'badge badge-' + order.status_color">{{ order.status_name }}</span>
            </div>
            <div class="col-2 text-right">{{ order.created_at_formated }}</div>
            <div class="col-1 text-right">{{ order.created_at_time_formated }}</div>
        </div>
    </a>

    <div class="d-flex justify-content-end mt-3">
        <div v-if="pagination.amount_pages > 1" class="list-item list-pagination">
            <ul role="navigation" class="pagination">
                <li class="page-item" v-for="page in pagination.pages"
                    :class="pagination.current_page == page ? 'active' : ''">
                    <a @click="getOrders(page)" class="page-link">{{ page }}</a>
                </li>
            </ul>
        </div>
    </div>

    <!--    <div class="row no-gutters mt-4">
            <div class="col-lg-2">
                <div class="statistics statistics-totals">
                    <small>Totaal producten</small>
                    {{ total_products }}
                </div>
            </div>
            <div v-for="category in categories" class="col-lg-2">
                <div class="statistics">
                    <small>{{ category.name }}</small>
                    {{ category.amount }}
                </div>
            </div>
        </div>-->
</template>

<script>
export default {
    props: [
        'statuses',
        'ordersCustomer',
        'ordersWarehouse',
    ],
    data() {
        const query = new URLSearchParams(window.location.search);
        const today = new Date().toISOString().split('T')[0];

        return {
            orders: {},
            pagination: {
                current_page: parseInt(query.get('page') ?? 1),
                total: '',
                amount_pages: '',
                pages: [],
            },
            filters: {
                organisations: [],
            },
            organisation: parseInt(query.get('organisation') ?? 0),
            from_date: query.get('from_date') ?? today,
            to_date: query.get('to_date') ?? today,
            amount: query.get('amount') ?? 50,
            warehouse: query.get('w') ?? '',
            subsequent_delivery: query.get('subsequent_delivery') ?? '',
            planned_orders: query.get('planned_orders') ?? '',
            organisation_results: {},
            categories: {},
            total_products: '',
            status: query.get('status') ?? 'all-positive',
            payment: query.get('p') ?? '',
        }
    },
    methods: {
        getOrders(page) {
            const spinner = document.getElementById('progress');
            spinner.innerHTML = '<div class="alert alert-info show text-center p-3 mt-3"><i class="far fa-spinner fa-spin"></i> Resultaten aan het laden...</div>';

            if (page) {
                this.pagination.current_page = page;
            }

            window.history.pushState(
                {s: this.search},
                "",
                "?page=" + this.pagination.current_page
                + "&organisation=" + this.organisation
                + "&from_date=" + this.from_date
                + "&to_date=" + this.to_date
                + "&amount=" + this.amount
                + "&status=" + this.status
                + "&w=" + this.warehouse
                + "&subsequent_delivery=" + this.subsequent_delivery
                + "&planned_orders=" + this.planned_orders
                + "&p=" + this.payment
            );

            axios({
                method: 'get',
                url: route('api.order-statistics-json'),
                params: {
                    page: this.pagination.current_page,
                    status: this.status,
                    from_date: this.from_date,
                    to_date: this.to_date,
                    amount: this.amount,
                    organisation: this.organisation,
                    warehouse: this.warehouse,
                    subsequent_delivery: this.subsequent_delivery,
                    planned_orders: this.planned_orders,
                    payment: this.payment,
                }
            }).then((response) => {
                this.orders = response.data.orders.data;
                this.pagination.total = response.data.orders.total;
                this.pagination.amount_pages = response.data.orders.last_page;
                this.organisation_results = response.data.organisations;
                this.categories = response.data.categories;
                this.total_products = response.data.total_products;
                this.pagination.pages = this.pageRange();
                spinner.innerHTML = '';
            });
        },
        searchbar() {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(() => {
                this.getOrders(1);
            }, 200);
        },
        pageRange() {
            if (this.pagination.amount_pages < 1) {
                return [1, 2, 3]
            }

            let current = this.pagination.current_page;
            let last = this.pagination.amount_pages;
            let delta = 2;
            let left = current - delta;
            let right = current + delta + 1;
            let range = [];
            let pages = [];
            let l;
            for (let i = 1; i <= last; i++) {
                if (i === 1 || i === last || (i >= left && i < right)) {
                    range.push(i);
                }
            }
            range.forEach(function (i) {
                if (l) {
                    if (i - l === 2) {
                        pages.push(l + 1);
                    } else if (i - l !== 1) {
                        pages.push('...');
                    }
                }
                pages.push(i);
                l = i;
            });
            return pages;
        },
        getOrganisations() {
            const self = this;
            axios({
                method: 'get',
                url: route('organisations.json'),
            }).then(function (response) {
                self.filters.organisations = response.data;
            })
        },
        countProducts(order) {
            return order?.products.reduce((total, orderProduct) => total + orderProduct.amount, 0) ?? 0;
        }
    },
    mounted() {
        this.getOrders(this.pagination.current_page);
        this.getOrganisations();
    }
};

</script>
